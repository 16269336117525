import {api} from '../../../../shared/services/backend-api'

const fetchAdoptionParameters = async (adoptionID) => {
  const {data} = await api.get(`hearth-adoptions/${adoptionID}`)
  const {
    children,
    monthlyCost,
    content
  } = data
  return {
    children,
    monthlyCost,
    content
  }
}

const updateAdoptionParameters = (adoption) => {
  const { children, monthlyCost, content, adoptionID } = adoption
  
  const payload = {
    children,
    monthlyCost,
    content
  }
  
  return api.put(`hearth-adoptions/${adoptionID}`, payload)
}

const fetchPurposeParameters = async (purposeID) => {
  const {data} = await api.get(`purposes/${purposeID}`)
  
  const {
    title,
    money,
    startedAt,
    content
  } = data
  
  return {
    title,
    money,
    startedAt,
    content
  }
}

const updatePurposeParameters = (purpose) => {
  const { purposeID, money, startedAt, content, title } = purpose
  
  const payload = {
    title,
    money,
    startedAt,
    content
  }
  
  return api.put(`purposes/${purposeID}`, payload)
}

export {
  fetchAdoptionParameters,
  updateAdoptionParameters,
  fetchPurposeParameters,
  updatePurposeParameters
}
