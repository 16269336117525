import i18n from '../../plugins/i18n'

const positiveNumberValidator = (_, value, callback) => {
  if (value < 0) {
    callback(new Error(`
    ${i18n.t('validators.number_value_positive')}
    `))
  }
  callback()
}


export {
  positiveNumberValidator
}
