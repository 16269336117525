<template>
  <el-row>
    <el-col :lg="{span: 10, offset: 7}">
      <el-card class="overflow-visible" v-loading.body="isLoading">
        <template #header>
          <div>
            <h2 class="heading page-heading">
              {{ $t('page_purpose_edit.title') }}
            </h2>
            <CurrentUnitInfo />
          </div>
        </template>
        <el-form
            label-position="top"
            :rules="formRules"
            size="medium"
            status-icon
            :model="form"
            ref="form"
        >
          <el-row :gutter="24">
            <el-col>
              <el-form-item
                  prop="title"
                  :label="$t('page_purpose_edit.fields.title')"
              >
                <el-input v-model.number="form.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :lg="12">
              <el-form-item
                  prop="money"
                  :label="$t('page_purpose_edit.fields.money')"
              >
                <el-input v-model.number="form.money"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :lg="12">
              <el-form-item
                  prop="startedAt"
                  :label="$t('page_purpose_edit.fields.startedAt')"
              >
                <el-date-picker
                    :editable="false"
                    v-model="form.startedAt"
                    type="date"
                    class="w-100"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                  prop="content"
                  :label="$t('page_purpose_edit.fields.content')"
              >
                <v-text-editor :allow-media-files="false" v-model="form.content"></v-text-editor>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <el-button type="primary" @click="handleUpdatePurpose">
                {{ $t('page_purpose_edit.btn_submit') }}
              </el-button>
              <el-button @click="goToUnitPage">
                {{ $t('page_purpose_edit.btn_cancel') }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {
  fetchPurposeParameters,
  updatePurposeParameters
} from '../shared/services/purpose-service'
import {numberValueValidator} from '../../../shared/validators/numberValueValidator'
import {positiveNumberValidator} from '../../../shared/validators/positiveNumberValidator'
import {mapState} from 'vuex'

import {UNIT_PURPOSES_ROUTE_NAME} from '../../../shared/constants/app-routes-names'
import VTextEditor from '../../../components/ui/VTextEditor'
import CurrentUnitInfo from '../components/current-unit-info/CurrentUnitInfo'

export default {
  name: 'UnitPurposeEdit',
  components: {CurrentUnitInfo, VTextEditor},
  data() {
    return {
      form: {
        title: '',
        money: 0,
        startedAt: '',
        content: ''
      },
      formRules: {
        money: [
          {required: true, message: this.$t('validators.field_required'),},
          {validator: numberValueValidator, trigger: 'change'},
          {validator: positiveNumberValidator, trigger: 'change'}
        ],
        title: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        startedAt: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        content: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
      }
    }
  },
  created() {
    this.fetchPurpose()
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    })
  },
  methods: {
    goToUnitPage() {
      this.$router.push({
        name: UNIT_PURPOSES_ROUTE_NAME,
        params: {
          unitID: this.$route.params.unitID
        }
      })
    },
    fetchPurpose() {
      const { purposeID } = this.$route.params
      fetchPurposeParameters(purposeID)
          .then((res) => {
            this.form = {
              ...res
            }
          })
          .catch((e) => {
            this.$message({
              type: 'error',
              message: e.data
            })
          })
    },
    parseDateString(v) {
      // todo: implement with dayjs
      const date = new Date(v)
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

      return `${year}-${month}-${day}`
    },
    handleUpdatePurpose() {
      this.$refs.form.validate((valid) => {
        if (!valid) return

        const { purposeID } = this.$route.params
        const {
          money,
          title,
          startedAt,
          content
        } = this.form
        updatePurposeParameters({
          purposeID,
          money,
          title,
          startedAt: this.parseDateString(startedAt),
          content
        })
          .then(() => {
            this.goToUnitPage()
            this.$message({
              message: `${this.$t('page_purpose_edit.messages.update_success')}`,
              type: 'success'
            })
          })
          .catch((e) => {
            this.$message({
              message: `${this.$t('page_purpose_edit.messages.edit_error')}`,
              type: 'error'
            })
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
